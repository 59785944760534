<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 text-center">
        <div class="text-center py-5">
          <h3 class="   ">今夏限定 C卡姆系列 </h3>
          <h4 class="  ">清爽加倍 新登場</h4>
          <h4 class="  ">擺脫悶熱油肌、粗大毛孔、頑固髒污</h4>
        </div>
        <router-link to="/productlist" class="card-hover">
            <div class="imgContainer ratio ratio-68x43">
              <img src="@/assets/img/event/camu_banner.jpg" alt="" >
            </div>
        </router-link>
        <section>
            <p class="py-5 text-justify px-2 px-lg-0">C卡姆的季節來了！隨著夏季氣溫越來越高，加上防疫不脫罩的狀態，肌膚長時間處於又熱又悶的環境下，導致各種肌膚問題產生。
            想要擺脫肌膚窘態，先從初夏清潔養膚步驟做起，讓你即使脫下口罩也能擁有光滑美肌。
            </p>
            <h4 class="text-justify">挑選夏季洗顏最佳選擇？</h4>
            <p class="pb-5 text-justify px-2 px-lg-0">夏天的炙熱豔陽高溫容易造成皮脂分泌過度旺盛，導致激增悶痘、毛孔髒汙、粉刺等層出不窮的狀況，這時應選擇深層潔淨溫和效果，能幫助肌膚油水平衡的洗顏粉，這樣才能在去除多餘油脂同時，又避免過度刺激肌膚。
            </p>
            <h4 class=" text-justify ">添加木瓜酵素與卡姆果萃取成分 洗後肌膚光滑柔嫩</h4>
            <p class=" text-justify px-2 px-lg-0">C卡姆系列添加天然卡姆果精華萃取(富含維生素Ｃ)、及木瓜酵素成分，深層卸妝油搭配潔顏粉的超細緻綿密泡沫，輕柔包覆肌膚，深層清潔毛孔，帶走髒污與黑頭粉刺，洗淨力卻不會讓肌膚緊繃乾澀，反而很有保濕感，連油肌使用也很有感。
            </p>
            <!-- <h3 class="fw-bold text-center py-1"></h3> -->
            <p class="py-5 text-justify px-2 px-lg-0">洗後肌膚光滑柔嫩，展現無暇零毛孔的美肌，且用量非常省，非常好起泡，再加上清新的柑橘香味，天天使用，夏日也能養出健康透亮的柔嫩玫瑰肌，C卡姆就是你獨一無二適合夏季肌膚選擇的最佳洗顏產品。
            </p>
            <h4 class="text-justify">獲得廣大迴響 「夏天洗顏非它不可」的好口碑!!</h4>
            <p class="pb-5 text-justify px-2 px-lg-0">C卡姆在日本連續15年暢銷，擁有著強大粉絲，且日本同步熱銷搶購中，台灣配額有限，千萬不要錯過一年一次的購買良機。
            </p>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
      .card-hover {
        .ratio{
          overflow: hidden;
        }
        &:hover{
          img{
            transform: scale(1.05);
            transition: all .8s;
          }
        }
    }
    .text-justify {
      text-align: justify;
    }
    .font-big {
      font-weight: 900;
    }
    p{
        line-height: 1.8;
    }
    h3,h4{
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
</style>
